import React from "react";
import cardImage from "../data/contact-card.jpeg"

export const Contact = (props) => {


  
  return (
    <div>
      <div id="contact">
        <div className="container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
         
          <div>
            <div className="contact-item">
            <h2>Get In Touch</h2>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
            </div>
            <img src={cardImage} alt="title" width="50%" style={{maxWidth: '50%', height: '100%'  }}/>  

           
          </div>
       
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Verdure Agritech
            
          </p>
        </div>
      </div>
    </div>
  );
};
