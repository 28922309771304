import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/DSC00065.jpeg" width="0%" className="img-responsive" alt="title" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About</h2>
              <p>James Pearsall is dedicated to finding innovative solutions for global food security. He is currently leading agricultural projects at the Stanford Farm as part of his Earth Systems master's degree program under the guidance of Stanford Farm director Patrick Archie. For the past seven years, he has gained extensive experience working at various international nurseries from Ecuador to Japan to Miami, focusing on orchid micropropagation, conservation, and husbandry.</p>
              <p>James also conducts personal research on urban farming initiatives, leveraging alternative food sources such as algae, jellyfish, and living walls to nurture a variety of sustainable practices. With an undergraduate degree in English from Stanford University, he is a natural storyteller with superior analytical and critical thinking abilities, using these skills to communicate the importance of sustainable agriculture.</p>
              <p>Whether mentoring high school students in nitrogen fixation and pest control, leading a team of volunteers on the Stanford Farm, or managing flower orders for events on campus, James continuously explores new methods to enhance food production and promote green business. He is excited to utilize his skills and continue learning and growing in the field of sustainable agriculture, with a vision of feeding everyone.</p>
              {/* <h3>Why Choose Us?</h3> 
               <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
