import React from "react";
// import { Image } from "./image";
// import logo from "../data/Feeding Everyone white.png"
import coverImage from "../data/cover-image.png"
export const Header = (props) => {
  return (



    <div
      // className="container"
      // style={{
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center",
      //   backgroundImage: "url('../img/gallery/DSC09998.jpeg')",
      //   backgroundPosition: "center center",
      //   backgroundRepeat: "no-repeat"
      // }} 
      >
      {/* <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1> 
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
               <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
             
              </div>
            </div> */}
             <div id="portfolio" className="text-center">
      <div className="photo-container">
      <img src={coverImage} alt="title" width="100%" />
      </div>
    </div>
    </div>



  );
};
